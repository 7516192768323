import * as React from 'react';

import ContactForm from '../components/contactform';
import Footer from '../components/footer';

import Header from "../components/header";

const Contact = () => (
  <React.Fragment>
    <Header />
    <ContactForm />
    <Footer />
  </React.Fragment>
);

export default Contact;