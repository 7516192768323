import * as React from "react";
import * as styles from "./contactform.module.css";
import classNames from "classnames";

import Button from './button';

const ContactForm = ({ columns }) => (
  <div className={classNames(styles.contactFormContainer, "container-fluid")}>
  <div className="row">
    <div className={classNames(styles.contactCard, "col-sm-12 col-md-6 offset-md-3")}>
      <h3>Send us a message!</h3>
      <br />

      <form
        action="https://formspree.io/f/xnqooyob"
        method="POST"
      >
        <div className="form-group">
          <input required name="name" type="text" className="form-control" id="contactName" aria-describedby="Your Name *" placeholder="Name (required)" />
        </div>
        <div required className="form-group">
          <input name="_replyto" type="email" className="form-control" id="contactEmail" aria-describedby="Your Email *" placeholder="Email (required)" />
        </div>
        <div required className="form-group">
          <input name="phone" type="phone" className="form-control" id="contactEmail" aria-describedby="Your phone number" placeholder="Phone" />
        </div>
        <div className="form-group">
          <textarea required className="form-control" id="exampleFormCemailMessageontrolTextarea1" rows="3" name="message" placeholder="Message (required)"></textarea>
        </div>
        <Button type="submit">Send</Button>
      </form>

    </div>
  </div>
</div>
);

export default ContactForm;
